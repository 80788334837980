@font-face {
    font-family: var(--wide-font);
    src: url("routed-gothic-wide-italic.woff2") format("woff2"),
        url("routed-gothic-wide-italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: var(--wide-font);
    src: url("routed-gothic-wide.woff2") format("woff2"),
        url("routed-gothic-wide.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: var(--normal-font);
    src: url("routed-gothic.woff2") format("woff2"),
        url("routed-gothic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: var(--normal-font);
    src: url("routed-gothic-italic.woff2") format("woff2"),
        url("routed-gothic-italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}
